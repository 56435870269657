
.close-button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  background-color: transparent;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 100;
}

.close-button:hover {
  opacity: 0.6;
}
.close-button .lr {
  height: 40px;
  width: 2px;
  margin-left: 35px;
  /*background-color: #000;*/
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.close-button .lr .rl {
  height: 40px;
  width: 2px;
  /*background-color: #000;*/
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}