body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px;
  color: #070A6D;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background-color: #FAF7F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: #070A6D;   
	outline: none;
  text-decoration: underline;
}

.wrapper {
    padding: 8% 10% 8% 10%;
}

.pageTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 80px;
  font-weight: 700;
  color: #070A6D;
  margin: 8% 0 2% 0;
  line-height: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pageSubtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  margin: 4% 0 2% 0;
  max-width: 580px;
  font-weight: 600;
  color: #070A6D;
  line-height: initial;
}

@media (max-width: 767px) {

  .pageTitle {
    font-size: 40px;
  }
  .pageSubtitle {
      max-width: 100%;
      font-size: 30px;
  }

  .wrapper {
    padding: 160px 10% 8% 10%;
  }
}