.wrapper {
	padding: 8% 10% 8% 10%;
}

#video {
	position: fixed;
    top: -30px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
}

.home > .pageTitle, .pageSubtitle {
    color: #EF8B7C;
}

.home > .pageTitle {
    font-size: 180px;
    line-height: 160px;
    width: 740px;
}

@media (max-width: 767px) {
	.wrapper {
		padding: 200px 10% 8% 10%;
	}
    .home > .pageTitle {
  	   font-size: 80px;
  	   line-height: 80px;
       max-width: 100%;
    }
	#video {
	  height: 100vh;
      width: auto;
	}
}